export function urlToBlob(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => resolve(blob))
      .catch((error) => reject(error));
  });
}

export function objectToBlob(object: any, type = 'application/json'): Blob {
  return new Blob([JSON.stringify(object, null, 2)], {
    type
  });
}
